<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="period">Period</label>
                    <Calendar :manualInput="false" v-model="period" :yearNavigator="true" view="month" dateFormat="MM yy" icon="pi pi-calendar" :showIcon="true"/>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>User</label>
                    <Dropdown id="userid" dataKey="userid" v-model="filters.userid" :loading="loadingDropdownUser"
                        :options="dataDropdownUser"
                        optionLabel="name" optionValue="userid" placeholder="Select User"
                        :filter="true" :showClear="true" @filter="searchDropdownUser($event, 'filter')" />
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Status Approval</label>
                     <Dropdown id="status" v-model="filters.status" :options="dataDropdownStatus" optionLabel="name" optionValue="code" placeholder="Pilih Status" />
                </div>
                <!-- <div class="field col-12 md:col-6 lg:col-3">
                    <label>Tipe Sales</label>
                     <Dropdown id="tipe_sales" v-model="filters.tipe_sales" :options="dataDropdownTipeSales" :showClear="true" optionLabel="name" optionValue="code" placeholder="Pilih Tipe Sales" />
                </div> -->
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>
import moment from 'moment';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownUser: false,

            // dataDropdown
            dataDropdownUser: null,
            dataDropdownStatus: [
                {name: 'Pending', code: 'Pending'},
                {name: 'Approved', code: 'Approved'},
                {name: 'Rejected', code: 'Rejected'},
            ],
            dataDropdownTipeSales: [
                {name: 'Tender', code: 'TENDER'},
                {name: 'Non Tender', code: 'NON TENDER'},
                {name: 'Regular', code: 'REGULAR'},
            ],

            // filter
            filters: {
                // tipe_sales: null,
                userid: null,
                status: 'Pending',
            },
            period: new Date(),
        }
    },
    mounted() {
        this.searchDropdownUser('');
    },
    computed:{
        period_label(){ 
            return moment(this.period, 'YYYY-MM').format('YYYY-MM'); 
        },
    },
    methods: {
        // DROPDOWN
        searchDropdownUser(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dterritory.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownUser = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/user-hp',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownUser = res.data.data;
                            this.loadingDropdownUser = false;
                        } else if (purpose == null) {
                            this.dataDropdownUser = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        getDataTable(){
            this.$emit('submit');
        }
    }
}
</script>

