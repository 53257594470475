<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <Error :errors="errors" />

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <Filter @submit="getDataTable()" ref="filter" />
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number"
                    @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit"
                    v-model:expandedRows="expandedRows">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <!-- <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel"
                                    class="p-button p-button-success mr-2 my-1 inline-block"
                                    @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel"
                                    class="p-button p-button-success mr-2 my-1 inline-block"
                                    @click="exportExcelCSV('csv')" />
                            </div> -->
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column :expander="true" :headerStyle="{ 'width': '3rem' }" />
                    <Column field="number" header="No." :style="{ width: '20px' }">
                        <template #body="slotProps">
                            {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="username" header="Username">
                        <template #body="slotProps">
                            {{ slotProps.data.username }}
                        </template>
                    </Column>
                    <Column field="name" header="Name">
                        <template #body="slotProps">
                            {{ slotProps.data.name }}
                        </template>
                    </Column>
                    <Column field="kode_outlet" header="Kode Outlet">
                        <template #body="slotProps">
                            {{ slotProps.data.kode_outlet }}
                        </template>
                    </Column>
                    <Column field="name_outlet" header="Nama Outlet">
                        <template #body="slotProps">
                            {{ slotProps.data.name_outlet }}
                        </template>
                    </Column>
                    <Column field="date" header="Time Input">
                        <template #body="slotProps">
                            {{ slotProps.data.date }}
                        </template>
                    </Column>
                    <Column field="is_approved_main_label" header="Status Approval">
                        <template #body="slotProps">
                            {{ slotProps.data.is_approved_main_label }}
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="min-width:10rem;">
                        <template #body="slotProps">
                            <div v-if='slotProps.data.is_approved_main_label == "Pending"'>
                                <Button :loading="slotProps.data.isLoadingY" label="Approve"
                                    class="p-button p-button-success mr-2 my-1 inline-block"
                                    @click="approvalItem(slotProps.data, 'y')" />
                                <Button :loading="slotProps.data.isLoadingN" label="Reject"
                                    class="p-button p-button-danger mr-2 my-1 inline-block"
                                    @click="approvalItem(slotProps.data, 'n')" />
                            </div>
                            <span v-if="slotProps.data.is_approved_main_label == 'Rejected'"
                                style="color:red">Rejected</span>
                            <span v-if="slotProps.data.is_approved_main_label == 'Approved'"
                                style="color:green">Approved</span>
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div class="orders-subtable">
                            <h5>Detail {{ slotProps.data.username }}</h5>
                            <DataTable :value="slotProps.data.produk">
                                <Column field="tipe_sales" header="Tipe Sales">
                                    <template #body="slotProps">
                                        {{ slotProps.data.tipe_sales }}
                                    </template>
                                </Column>
                                <Column field="kode_produk" header="Kode Produk">
                                    <template #body="slotProps">
                                        {{ slotProps.data.kode_produk }}
                                    </template>
                                </Column>
                                <Column field="name_produk" header="Nama Produk">
                                    <template #body="slotProps">
                                        {{ slotProps.data.name_produk }}
                                    </template>
                                </Column>
                                <Column field="qty1_label" header="QTY 1">
                                    <template #body="slotProps">
                                        {{ slotProps.data.qty1_label }}
                                    </template>
                                </Column>
                                <Column field="qty2_label" header="QTY 2">
                                    <template #body="slotProps">
                                        {{ slotProps.data.qty2_label }}
                                    </template>
                                </Column>
                                <Column field="qty3_label" header="QTY 3">
                                    <template #body="slotProps">
                                        {{ slotProps.data.qty3_label }}
                                    </template>
                                </Column>
                                <Column field="qty4_label" header="QTY 4">
                                    <template #body="slotProps">
                                        {{ slotProps.data.qty4_label }}
                                    </template>
                                </Column>
                                <Column field="qty5_label" header="QTY 5">
                                    <template #body="slotProps">
                                        {{ slotProps.data.qty5_label }}
                                    </template>
                                </Column>
                                <Column field="price1" header="Harga 1">
                                    <template #body="slotProps">
                                        {{ priceFormat(slotProps.data.price1) }}
                                    </template>
                                </Column>
                                <Column field="price2" header="Harga 2">
                                    <template #body="slotProps">
                                        {{ priceFormat(slotProps.data.price2) }}
                                    </template>
                                </Column>
                                <Column field="price3" header="Harga 3">
                                    <template #body="slotProps">
                                        {{ priceFormat(slotProps.data.price3) }}
                                    </template>
                                </Column>
                                <Column field="price4" header="Harga 4">
                                    <template #body="slotProps">
                                        {{ priceFormat(slotProps.data.price4) }}
                                    </template>
                                </Column>
                                <Column field="price5" header="Harga 5">
                                    <template #body="slotProps">
                                        {{ priceFormat(slotProps.data.price5) }}
                                    </template>
                                </Column>
                                <Column field="nilai_diskon1" header="Diskon 1">
                                    <template #body="slotProps">
                                        {{ slotProps.data.nilai_diskon1 }}
                                    </template>
                                </Column>
                                <Column field="nilai_diskon2" header="Diskon 2">
                                    <template #body="slotProps">
                                        {{ slotProps.data.nilai_diskon2 }}
                                    </template>
                                </Column>
                                <Column field="nilai_diskon3" header="Diskon 3">
                                    <template #body="slotProps">
                                        {{ slotProps.data.nilai_diskon3 }}
                                    </template>
                                </Column>
                                <Column field="nilai_diskon4" header="Diskon 4">
                                    <template #body="slotProps">
                                        {{ slotProps.data.nilai_diskon4 }}
                                    </template>
                                </Column>
                                <Column field="nilai_diskon5" header="Diskon 5">
                                    <template #body="slotProps">
                                        {{ slotProps.data.nilai_diskon5 }}
                                    </template>
                                </Column>
                                <Column field="" header="Total">
                                    <template #body="slotProps">
                                        {{ priceFormat(slotProps.data.total) }}
                                    </template>
                                </Column>
                                <Column field="" header="Edit Item">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2 mb-2"
                                            @click="editItem(slotProps.data)"
                                            v-if="slotProps.data.order_detail_approved == 'p'" />

                                        <span v-if="slotProps.data.order_detail_approved == 'n'"
                                            style="color:red">Rejected</span>
                                            <span v-if="slotProps.data.order_detail_approved == 'y'"
                                style="color:green">Approved</span>
                                    </template>

                                </Column>
                                <!-- <Column field="free_goods" header="Free Goods">
                                    <template #body="slotProps">
                                        {{slotProps.data.free_goods}}
                                    </template>
                                </Column> -->
                            </DataTable>
                        </div>
                    </template>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{ totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount"
                    :rowsPerPageOptions="[10, 20, 30]">
                    <template>
                        {{ totalItemsCount }}
                    </template>
                </Paginator>

                <Dialog v-model:visible="editItemDialog" :style="{ width: '400px', height: 'auto' }"
                    header="Detil Produk" :modal="true" class="p-fluid">

                    <TabView>
                        <TabPanel header="Quantity">
                            <div class="field">
                                <label for="Qty1">Qty 1</label>
                                <InputNumber id="qty1" v-model="item.qty1" required="true" autofocus
                                    :class="{ 'p-invalid': errorEdit.qty1 }"
                                    :disabled="disableEditText(item.qty1_label)" />
                                <small class="p-invalid" style="color: red" v-if="errorEdit.qty1">{{ errorEdit.qty1[0]
                                    }}</small>
                            </div>
                            <div class="field">
                                <label for="Qty1">Qty 2</label>
                                <InputNumber id="qty2" v-model="item.qty2" required="true" autofocus
                                    :class="{ 'p-invalid': errorEdit.qty2 }"
                                    :disabled="disableEditText(item.qty2_label)" />
                                <small class="p-invalid" style="color: red" v-if="errorEdit.qty2">{{ errorEdit.qty2[0]
                                    }}</small>
                            </div>
                            <div class="field">
                                <label for="Qty1">Qty 3</label>
                                <InputNumber id="qty3" v-model="item.qty3" required="true" autofocus
                                    :class="{ 'p-invalid': errorEdit.qty3 }"
                                    :disabled="disableEditText(item.qty3_label)" />
                                <small class="p-invalid" style="color: red" v-if="errorEdit.qty3">{{ errorEdit.qty3[0]
                                    }}</small>
                            </div>
                            <div class="field">
                                <label for="Qty4">Qty 4</label>
                                <InputNumber id="qty4" v-model="item.qty4" required="true" autofocus
                                    :class="{ 'p-invalid': errorEdit.qty4 }"
                                    :disabled="disableEditText(item.qty4_label)" />
                                <small class="p-invalid" style="color: red" v-if="errorEdit.qty4">{{ errorEdit.qty4[0]
                                    }}</small>
                            </div>
                            <div class="field">
                                <label for="Qty5">Qty 5</label>
                                <InputNumber id="qty5" v-model="item.qty5" required="true" autofocus
                                    :class="{ 'p-invalid': errorEdit.qty5 }"
                                    :disabled="disableEditText(item.qty5_label)" />
                                <small class="p-invalid" style="color: red" v-if="errorEdit.qty5">{{ errorEdit.qty5[0]
                                    }}</small>
                            </div>
                        </TabPanel>
                        <TabPanel header="Diskon">
                            <div class="field">
                                <label for="nilai_diskon1">Diskon 1</label>
                                <InputNumber id="nilai_diskon1" v-model="item.nilai_diskon1" required="true" autofocus
                                    :class="{ 'p-invalid': errorEdit.nilai_diskon1 }" :onChange="countDisc5()"
                                    max="100" />
                                <small class="p-invalid" style="color: red" v-if="errorEdit.nilai_diskon1">{{
                    errorEdit.nilai_diskon1[0]
                }}</small>
                            </div>
                            <div class="field">
                                <label for="nilai_diskon2">Diskon 2</label>
                                <InputNumber id="nilai_diskon2" v-model="item.nilai_diskon2" required="true" autofocus
                                    :class="{ 'p-invalid': errorEdit.nilai_diskon2 }" :onChange="countDisc5()"
                                    max="100" />
                                <small class="p-invalid" style="color: red" v-if="errorEdit.nilai_diskon2">{{
                    errorEdit.nilai_diskon2[0]
                }}</small>
                            </div>
                            <div class="field">
                                <label for="nilai_diskon3">Diskon 3</label>
                                <InputNumber id="nilai_diskon3" v-model="item.nilai_diskon3" required="true" autofocus
                                    :class="{ 'p-invalid': errorEdit.nilai_diskon3 }" :onChange="countDisc5()"
                                    max="100" />
                                <small class="p-invalid" style="color: red" v-if="errorEdit.nilai_diskon3">{{
                    errorEdit.nilai_diskon3[0]
                }}</small>
                            </div>
                            <div class="field">
                                <label for="nilai_diskon4">Diskon 4</label>
                                <InputNumber id="nilai_diskon4" v-model="item.nilai_diskon4" required="true" autofocus
                                    :class="{ 'p-invalid': errorEdit.nilai_diskon4 }" :onChange="countDisc5()"
                                    max="100" />
                                <small class="p-invalid" style="color: red" v-if="errorEdit.nilai_diskon4">{{
                    errorEdit.nilai_diskon4[0]
                }}</small>
                            </div>
                            <div class="field">
                                <label for="nilai_diskon5">Diskon 5</label>
                                <InputNumber id="nilai_diskon5" v-model="item.nilai_diskon5" required="true" autofocus
                                    :class="{ 'p-invalid': errorEdit.nilai_diskon5 }" :max="maxDisc5" />
                                <small class="p-invalid" style="color: red" v-if="errorEdit.nilai_diskon5">{{
                                    errorEdit.nilai_diskon5[0]
                                    }}</small>
                            </div>
                        </TabPanel>

                    </TabView>

                    <template #footer>
                        <!-- <Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loadingEdit"/> -->

                        <Button label="Reject" class="p-button-danger mr-2 mb-2" :style="{float:'left'}"
                            @click="rejectItem" />
                        <Button label="Batal" class="p-button-secondary mr-2 mb-2" @click="hideDialog" />
                        <Button label="Simpan" class="p-button-success mr-2 mb-2" @click="updateItem"
                            :loading="loadingEdit" />
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';
import Filter from '../../components/master/approval-order/FilterStatus.vue';
import Error from '../../components/Error.vue';

export default {
    components: {
        'Error': Error,
        'Filter': Filter,
    },
    data() {
        return {
            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,

            // approve,reject
            item: {},
            editItemDialog: false,

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,

            //expand datatable
            expandedRows: [],

            //other
            maxDisc5: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        }
    },
    computed: {
        ...mapGetters(['errors', 'errorEdit']),
    },
    methods: {
        priceFormat(value) {
            return new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR"
            }).format(value);
        },
        disableEditText(value) {
            if (value.includes('CTN') || value.includes('HNG') || value.includes('PCS')) {
                return false;
            } else {
                return true;
            }
        },
        formatDate(value) {
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // APPROVE
        approvalItem(item, status) {

            if (status == 'y') {
                item.isLoadingY = true;
            } else {
                item.isLoadingN = true;
            }

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/approval-order/approval',
                data: {
                    "ordermain_id": item.ordermain_id,
                    "status": status,
                },
            })
                .then((res) => {

                    if (status == 'y') {
                        item.isLoadingY = false;
                    } else {
                        item.isLoadingN = false;
                    }

                    if (res) {
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Updated', life: 3000, });
                        this.$store.commit('setErrors', {});
                        // this.clearForms();
                        this.getDataTable();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    if (status == 'y') {
                        item.isLoadingY = false;
                    } else {
                        item.isLoadingN = false;
                    }
                    this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Updated', life: 3000, });
                });
        },
        // DATATABLE
        getDataTable() {

            this.loading = true;
            this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/approval-order',
                params: {
                    "search": this.search,
                    "per_page": this.rows,
                    "page": this.page,
                    "order_by": this.field,
                    "sort_by": this.sort,
                    "date": this.$refs.filter.period_label,
                    "userid": this.$refs.filter.filters.userid,
                    "status": this.$refs.filter.filters.status,
                }
            })
                .then(res => {
                    this.data = res.data.data.data;
                    this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
                    this.totalItemsCount = res.data.data.total;
                    this.rows = parseInt(res.data.data.per_page);
                    this.loading = false;
                    this.$refs.filter.loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                    this.$refs.filter.loading = false;
                });

        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext) {

            if (ext == 'xlsx') {
                this.loadingExcel = true;
            } else if (ext == 'csv') {
                this.loadingCsv = true;
            }

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/approval-order/export',
                responseType: 'blob',
                params: {
                    "ext": ext,
                    // "tipe_sales": this.$refs.filter.filters.tipe_sales,
                    "date": this.$refs.filter.period_label,
                    "userid": this.$refs.filter.filters.userid,
                    "status": this.$refs.filter.filters.status,
                }
            })
                .then(response => {
                    let fileUrl = window.URL.createObjectURL(response.data);
                    let fileLink = document.createElement('a');

                    fileLink.href = fileUrl;

                    fileLink.setAttribute('download', 'Approval Order Export.' + ext);

                    document.body.appendChild(fileLink)

                    fileLink.click();

                    if (ext == 'xlsx') {
                        this.loadingExcel = false;
                    } else if (ext == 'csv') {
                        this.loadingCsv = false;
                    }

                })
                .catch((err) => {
                    console.log(err);

                    if (ext == 'xlsx') {
                        this.loadingExcel = false;
                    } else if (ext == 'csv') {
                        this.loadingCsv = false;
                    }
                });
        },
        editItem(item) {
            this.item = item;

            // dropdown 
            // this.searchDropdownLevel('', 'edit', item.level.level_name);
            // this.searchDropdownDMS('', 'edit', item.employee_dms);

            this.editItemDialog = true;
            this.$store.commit('setErrorEdit', {});

        },
        hideDialog() {
            this.editItemDialog = false;
            this.submitted = false;
            this.maxDisc5 = 0;
        },
        countDisc5() {
            let total = (this.item.qty1 * this.item.price1) + (this.item.qty2 * this.item.price2) + (this.item.qty3 * this.item.price3) + (this.item.qty4 * this.item.price4) + (this.item.qty5 * this.item.price5);

            let disc5 = total;
            if (this.item.nilai_diskon1 != null && this.item.nilai_diskon1 != 0) {
                disc5 = disc5 - (disc5 * this.item.nilai_diskon1 / 100);
            }

            if (this.item.nilai_diskon2 != null && this.item.nilai_diskon2 != 0) {
                disc5 = disc5 - (disc5 * this.item.nilai_diskon2 / 100);
            }

            if (this.item.nilai_diskon3 != null && this.item.nilai_diskon3 != 0) {
                disc5 = disc5 - (disc5 * this.item.nilai_diskon3 / 100);
            }

            if (this.item.nilai_diskon4 != null && this.item.nilai_diskon4 != 0) {
                disc5 = disc5 - (disc5 * this.item.nilai_diskon4 / 100);
            }

            this.maxDisc5 = disc5;
        },
        updateItem() {
            this.loadingEdit = true;

            if (this.item.nilai_diskon1 > 100 || this.item.nilai_diskon2 > 100 || this.item.nilai_diskon3 > 100 || this.item.nilai_diskon4 > 100) {
                alert("Nilai diskon tidak bisa melebihi 100 %");
            } else if (this.item.nilai_diskon5 > this.maxDisc5) {
                alert("Nilai diskon 5 tidak boleh lebih dari " + this.maxDisc5);
            } else {
                this.axios({
                    method: 'POST',
                    url: process.env.VUE_APP_ROOT_API + 'web/approval-order/update',
                    data: {
                        "order_id": this.item.order_id,
                        "qty1": this.item.qty1,
                        "qty2": this.item.qty2,
                        "qty3": this.item.qty3,
                        "qty4": this.item.qty4,
                        "qty5": this.item.qty5,
                        "nilai_diskon1": this.item.nilai_diskon1,
                        "nilai_diskon2": this.item.nilai_diskon2,
                        "nilai_diskon3": this.item.nilai_diskon3,
                        "nilai_diskon4": this.item.nilai_diskon4,
                        "nilai_diskon5": this.item.nilai_diskon5,
                    },
                })
                    .then((res) => {
                        this.loadingEdit = false;
                        if (res) {
                            this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Diupdate', life: 3000, });
                            this.$store.commit('setErrors', {});
                            this.$store.commit('setErrorEdit', {});
                            this.item = {};
                            this.editItemDialog = false;
                            this.getDataTable();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.loadingEdit = false;
                        this.$store.commit('setErrorEdit', err.response.data.data);
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Diperbarui', life: 3000, });
                    });
            }

        },
        rejectItem() {
            if (confirm("Apakah anda akan menghapus Order item ini?")) {
                this.axios({
                    method: 'POST',
                    url: process.env.VUE_APP_ROOT_API + 'web/approval-order/reject-item',
                    data: {
                        "order_id": this.item.order_id,
                    },
                })
                    .then((res) => {
                        this.loadingEdit = false;
                        if (res) {
                            this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Diupdate', life: 3000, });
                            this.$store.commit('setErrors', {});
                            this.$store.commit('setErrorEdit', {});
                            this.item = {};
                            this.editItemDialog = false;
                            this.getDataTable();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.loadingEdit = false;
                        this.$store.commit('setErrorEdit', err.response.data.data);
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Diperbarui', life: 3000, });
                    });
            }
        },
        // approvalItem(item, status) {

        //     if (status == 'y') {
        //         item.isLoadingY = true;
        //     } else {
        //         item.isLoadingN = true;
        //     }

        //     this.axios({
        //         method: 'POST',
        //         url: process.env.VUE_APP_ROOT_API + 'web/approval-order/approval',
        //         data: {
        //             "ordermain_id": item.ordermain_id,
        //             "status": status,
        //         },
        //     })
        //         .then((res) => {

        //             if (status == 'y') {
        //                 item.isLoadingY = false;
        //             } else {
        //                 item.isLoadingN = false;
        //             }

        //             if (res) {
        //                 this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Updated', life: 3000, });
        //                 this.$store.commit('setErrors', {});
        //                 // this.clearForms();
        //                 this.getDataTable();
        //             }
        //         })
        //         .catch((err) => {
        //             console.log(err);
        //             if (status == 'y') {
        //                 item.isLoadingY = false;
        //             } else {
        //                 item.isLoadingN = false;
        //             }
        //             this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Updated', life: 3000, });
        //         });
        // },
    }
}
</script>